import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../Blog/blogList.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

export default function BlogList() {
    return(
        <div className="view-container">
            <Breadcrumbs 
                items={[
                    {
                        title: 'Головна',
                        url: '/'
                    }, {
                        title: 'Новини',
                    }
                ]}
            />
            <h2 className="view-title view-title--h2">
                Блог
            </h2>

            <div className="view-blog-list">
                <StaticQuery
                    query={graphql`
                        {
                            allMdx(sort: {fields: frontmatter___date, order: DESC}) {
                                edges {
                                    node {
                                        id
                                        frontmatter {
                                            title
                                            summary
                                            date(formatString: "DD-MM-YYYY")
                                            thumbnail {
                                                childImageSharp {
                                                    fluid {
                                                        # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                                                        ...GatsbyImageSharpFluid
                                                    }
                                                }
                                            }
                                        }
                                        fields {
                                            slug
                                        }
                                    }
                                }
                            }
                        }
                    `}
                    render={data => {
                        const {edges: posts} = data.allMdx;
                        return (
                            posts.map(({ node: post }) => (
                                <div key={post.id} className="view-blog-list__item">
                                    <Link to={post.fields.slug} className="view-blog-list__item__image">
                                        <Img 
                                            fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                                            alt="#"/>
                                    </Link>
                                    <time className="view-blog-list__item__date">
                                        {post.frontmatter.date}
                                    </time>
                                    <Link to={post.fields.slug} className="view-blog-list__item__title">
                                        {post.frontmatter.title}
                                    </Link>
                                    <div className="view-blog-list__item__summary">
                                        {post.frontmatter.summary}
                                    </div>
                                </div>
                            ))
                        )
                    }}
                ></StaticQuery>
            </div>
        </div>
    );
}