import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogList from '../components/Blog/BlogList';

const BlogIndex = ({ data }) => {
  return (
    <Layout>
      <SEO title="Блог" />
      <BlogList />
    </Layout>
  );
};

export default BlogIndex;
